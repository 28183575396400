import { getField, updateField } from 'vuex-map-fields'

const state = {
  profile: {},
}

const getters = {
  getField,
  profile: state => state.profile,
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_ACCOUNT_DATA(state, payload) {
    state.profile = payload
  },
  updateField,
}

const actions = {
  save: async ({ state }) => {
    console.log(state)
  },

  getAccount: async ({ commit }) => {
    const data = await window.http.get('me/profile', { loading: false })
    if (data.error) {
      return null
    }
    commit('SET_ACCOUNT_DATA', data)
    return data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
