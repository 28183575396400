import Vue, { getCurrentInstance } from 'vue'

const ParseQuerySearch = {

  parseQuerySearch() {
    const vm = getCurrentInstance()
    if (!vm.proxy.$route.query.search) {
      return
    }
    const search = {}
    const query = vm.proxy.$route.query.search.split(';')
    query.forEach(item => {
      const field = item.split(':')
      if (field[1] === undefined) {
        return
      }
      const fieldName = field[0]
      // eslint-disable-next-line prefer-destructuring
      const value = field[1]
      search[fieldName] = value
    })
    console.log(search)
    // this.search = search
  },
  getCurrentPage() {
    if (!this.$route.query.page) {
      return {
        page: 1,
        per_page: 25,
      }
    }

    return {
      page: this.$route.query.page || 1,
      per_page: this.$route.query.per_page || 25,
    }
  },
}
Vue.prototype.$parseQuery = ParseQuerySearch
window.parseQuery = ParseQuerySearch
export default ParseQuerySearch
