import Vue from 'vue'

const recordsPerPage = 100

const ApiSearch = {
  meta: {
    page: 1,
    per_page: recordsPerPage,
  },

  async get(endPoint, meta, search, includes, save = false) {
    if (meta === null || meta === undefined) {
      meta = {
        page: 1,
        per_page: recordsPerPage,
      }
    }
    let stringQuery = '?'
    if (endPoint.indexOf('?') !== -1) {
      stringQuery = '&'
    }
    const searchString = this.parseSearchString(search)
    let url = `${endPoint}${stringQuery}page=${meta.page || 1}&per_page=${meta.per_page || recordsPerPage}`
    const parts = []
    if (searchString !== '') {
      parts.push(`search=${searchString}`)
    }

    if (save) {
      const searchURL = new URL(window.location)
      searchURL.searchParams.set('search', searchString)
      searchURL.searchParams.set('page', meta.page || 1)
      searchURL.searchParams.set('per_page', meta.per_page || 25)
      window.history.pushState({}, '', searchURL)
    }
    if (includes) {
      parts.push(`include=${includes}`)
    }
    if (meta.sort_field) {
      parts.push(`sort_field=${meta.sort_field}`)
    }
    if (meta.sort_order) {
      parts.push(`sort_order=${meta.sort_order}`)
    }
    url += `&${parts.join('&')}`
    return window.http.get(url)
  },
  parseSearchString(search) {
    return window.queryBuilder.parser(search)
  },

}
Vue.prototype.$search = ApiSearch
window.apiSearch = ApiSearch
export default ApiSearch
