import Vue from 'vue'
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardFooter,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BSidebar,
  BTab,
  BTabs, BContainer, VBTooltip, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import I2FormInputMoney from '@/components/i2/forms/elements/I2FormInputMoney.vue'
import I2IconButton from '@/components/i2/forms/elements/I2IconButton'
import ButtonClose from '@/components/app/ButtonClose.vue'
import ButtonSave from '@/components/app/ButtonSave.vue'
import I2FormInputFile from '@/components/i2/forms/elements/I2FormInputFile.vue'
import I2FormAddressInput from '@/components/i2/forms/elements/I2FormAddressInput.vue'

Vue.component('BFormInput', BFormInput)
Vue.component('BInputGroup', BInputGroup)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BButton', BButton)
Vue.component('BInputGroupAppend', BInputGroupAppend)
Vue.component('BRow', BRow)
Vue.component('BCol', BCol)
Vue.component('BForm', BForm)
Vue.component('BAlert', BAlert)
Vue.component('BBadge', BBadge)
Vue.component('BTabs', BTabs)
Vue.component('BFormTextarea', BFormTextarea)
Vue.component('BTab', BTab)
Vue.component('BImg', BImg)
Vue.component('BCard', BCard)
Vue.component('BDropdownItem', BDropdownItem)
Vue.component('BDropdown', BDropdown)
Vue.component('BFormInvalidFeedback', BFormInvalidFeedback)
Vue.component('BSidebar', BSidebar)
Vue.component('VSelect', vSelect)
Vue.component('ToastificationContent', ToastificationContent)
Vue.component('BCardBody', BCardBody)
Vue.component('BFormCheckbox', BFormCheckbox)
Vue.component('I2FormInputMoney', I2FormInputMoney)
Vue.component('I2FormInputFile', I2FormInputFile)
Vue.component('I2FormAddressInput', I2FormAddressInput)
Vue.component('BCardTitle', BCardTitle)
Vue.component('BCardHeader', BCardHeader)
Vue.component('BCardFooter', BCardFooter)
Vue.component('BCardText', BCardText)
Vue.component('BFormFile', BFormFile)
Vue.component('I2IconButton', I2IconButton)
Vue.component('BContainer', BContainer)
Vue.component('VBTooltip', VBTooltip)
Vue.component('BListGroup', BListGroup)
Vue.component('BListGroupItem', BListGroupItem)
Vue.component('ButtonClose', ButtonClose)
Vue.component('ButtonSave', ButtonSave)
