const opportunity = [
  {
    path: '/opportunities',
    name: 'opportunities.list',
    component: () => import('@/views/opportunity/OpportunityList.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },
  },

  {
    path: '/opportunities/:uuid/detail',
    name: 'opportunities.admin.detail',
    component: () => import('@/views/opportunity/OpportunityAdminDetail.vue'),
    meta: {
      roles: ['super_admin', 'admin'],
    },
  },
  {
    path: '/opportunities/search',
    name: 'opportunities.search',
    component: () => import('@/views/opportunity/Search.vue'),
    meta: {
      public: true,
    },
  },
]

export default opportunity
