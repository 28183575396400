<template>
  <div>
    <b-card v-if="currentBreakPoint !== 'xs'" md="12">
      <b-row>
        <b-col md="6">
          <h2>
            {{ name }}
          </h2>
        </b-col>
        <b-col
          md="6"
          class="text-right"
        >
          <slot/>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="currentBreakPoint === 'xs'">
      <b-row>
        <b-col md="6">
          <h5>
            {{ name }}
          </h5>
        </b-col>
        <b-col
          md="6"
          class="text-right"
        >
          <slot/>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PageHeader',
  components: {},
  props: ['name'],
  data: () => ({}),
  mounted() {
    console.log(this.currentBreakPoint)
  },

  computed: {
    ...mapGetters('app', ['currentBreakPoint']),
  },
  methods: {},
}
</script>

<style scoped>

</style>
